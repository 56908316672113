import React from 'react'
import axios from 'axios'
import remark from 'remark'
import remarkHtml from 'remark-html'
import recommended from 'remark-preset-lint-recommended'
import { HTMLContent } from '../components/Content'
import Layout from '../components/layout'
import { getUrlVars, getImage } from '../util'
import { formatDate } from '../util/events'

class PreviewPage extends React.Component {
    constructor(props) {
        super(props);
        const { key, id, type } = getUrlVars(this.props.location.href)
        this.state = {key, id, type}
    }

    componentDidMount () {
        this.getBlogPost()
    }

    getBlogPost = () => {
        axios.get(`${process.env.GATSBY_PREVIEW_BASE_URL}?type=${this.state.type}&id=${this.state.id}`, {
            headers: {
                'Authorization': this.state.key,
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(response => {
            let data = {}
            if (response.data.blogPost) {
                data = response.data.blogPost
            } else if (response.data.page) {
                data = response.data.page
            }
            if (data !== {}){         
                const content = remark()
                    .use(recommended)
                    .use(remarkHtml)
                    .processSync(data.content)
                    .toString()
                data.content = content
                response.data.blogPost ? this.setState({post: data}) : response.data.page ? this.setState({page: data}) : data = {}
            }
        })
        .catch((error) => {
            this.setState({error: error.response.data.error})
            console.log(error.response)
        });
    }

    render () {
        return (
            <Layout>
                <section className="section grid-container">
                    <div className="grid-x grid-margin-x content">
                        <div className="cell small-8">
                            {!this.state.post &&!this.state.page && !this.state.error && (<p>Loading...</p>)}
                            {this.state.error ? (<p>{this.state.error}</p>) : ""}
                            {this.state.post && (
                            <>
                                {getImage(this.state.post.featuredImage, this.state.post.title)}
                                <p className="post-date">{formatDate(this.state.post.publishedOn)}</p>
                                <h1>
                                {this.state.post.title}
                                </h1>
                                <HTMLContent content={this.state.post.content} />
                                <p className="author-byline">Posted by: {this.state.post.author.name}</p>
                            </>)
                            }
                            {this.state.page && (
                            <>
                                <div className="cell small-8">
                                {getImage(this.state.page.featuredImage, this.state.page.title)}
                                    <p className="post-date">{formatDate(this.state.page.publishedOn)}</p>
                                    <h1>
                                    {this.state.page.title}
                                    </h1>
                                    <HTMLContent content={this.state.page.content} />
                                </div>
                            </>)}
                        </div>
                    </div>
                </section>
            </Layout>
          )
    }
}

export default PreviewPage